import "./Bg.css";
function ContactInfo() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <h3>Reach out to me at</h3>
        </div>
        <div className="col-sm-12">
          <p>
            <b>Email:</b> aishaaaali19@gmail.com
          </p>
          <p className="mb-200">
            <b>Phone:</b> +918374218303
          </p>
        </div>
      </div>
    </div>
  );
}
export default ContactInfo;
