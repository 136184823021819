function Welcome() {
  return (
    <section>
      <div className="layout">
        <h2>Welcome to my portfolio</h2>
      </div>
    </section>
  );
}

export default Welcome;
