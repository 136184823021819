import React from "react";
import styled from "styled-components";
import "./Bg.css";
import "./OverlayBg";
import OverlayBg from "./OverlayBg";
import Welcome from "./Welcome";

const BgDynamic = styled.div`
position: relative;
  font-size: 4.8em;
  position: relative;
  background-color: #222;
  min-height: 100vh;
  background-image: linear-gradient(
      20deg,
      transparent 69%,
      ${(props) => props.generateColor},
      transparent 70%
    ),
    linear-gradient(
      -20deg,
      transparent 69%,
      ${(props) => props.generateColor},
      transparent 70%
    ),
    linear-gradient(
      -25deg,
      transparent 34%,
      ${(props) => props.generateColor},
      transparent 35%
    ),
    linear-gradient(
      25deg,
      transparent 34%,
      ${(props) => props.generateColor},
      transparent 35%
    ),
    linear-gradient(
      60deg,
      transparent 69%,
      ${(props) => props.generateColor},
      transparent 70%
    ),
    linear-gradient(
      -60deg,
      transparent 69%,
      ${(props) => props.generateColor},
      transparent 70%
    ),
    linear-gradient(
      -65deg,
      transparent 34%,
      ${(props) => props.generateColor},
      transparent 35%
    ),
    linear-gradient(
      65deg,
      transparent 34%,
      ${(props) => props.generateColor},
      transparent 35%
    );
  background-size: 5em 2em, 5em 2em, 5em 2em, 5em 2em, 2em 5em, 2em 5em, 2em 5em,
    2em 5em;

    -webkit-animation: rainbow 200s ease-in-out infinite;
    -z-animation: rainbow 80s ease-in-out infinite;
    -o-animation: rainbow 80s ease-in-out infinite;
      animation: rainbow 200s ease-in-out infinite;
    
    @-webkit-keyframes rainbow {
        0%{background-position:0% 82%}
        50%{background-position:100% 19%}
        100%{background-position:0% 82%}
    }
    @-moz-keyframes rainbow {
        0%{background-position:0% 82%}
        50%{background-position:100% 19%}
        100%{background-position:0% 82%}
    }
    @-o-keyframes rainbow {
        0%{background-position:0% 82%}
        50%{background-position:100% 19%}
        100%{background-position:0% 82%}
    }
    @keyframes rainbow { 
        0%{background-position:0% 82%}
        50%{background-position:100% 19%}
        100%{background-position:0% 82%}
    }
    
  }
`;

const OverlayBgStyles = styled.div`
  font-family: "Open Sans Condensed", sans-serif;
  text-transform: uppercase;
  lineheight: 1.5;
  text-align: center;
  margin: 0 auto;
  position: relative;
  background: #222;
  color: #fff;
  // box-shadow: inset 0 2px black, inset 0 10px 10px rgba(black, 0.8);
`;

function App() {
  const generateColor = () => {
    const randomColor =
       `${"hsla("}` +
      `${360 * Math.random()}` +
      `${","}` +
      `${"70%,"}` +
      `${"80%,1)"}`;
    return `${randomColor}`;
  };

  // useEffect(() => {
  //   generateColor();
  // }, []);

  // setInterval(generateColor, 500);

  return (
    <div>
      <BgDynamic generateColor={generateColor}>
        <Welcome></Welcome>
      </BgDynamic>
      <OverlayBgStyles>
        <OverlayBg></OverlayBg>
      </OverlayBgStyles>
    </div>
  );
}

export default App;
