// import { React, Fragment, useEffect, useLayoutEffect, useRef } from "react";
import React from "react";
import styled from "styled-components";
import Experience from "./Experience";
import ContactInfo from "./ContactInfo";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
} from "react-scroll-motion";

import "./Bg.css";

// // typical import
// import { gsap } from "gsap";
// // get other plugins:
// import ScrollTrigger from "gsap/ScrollTrigger";
// import Flip from "gsap/Flip";
// import Draggable from "gsap/Draggable";

// or all tools are exported from the "all" file (excluding members-only plugins):
// import {
//   gsap,
//   ScrollTrigger,
//   Draggable,
//   Flip,
//   MotionPathPlugin,
// } from "gsap/all";

// gsap.registerPlugin(ScrollTrigger, Draggable, Flip);

const FunkyText = styled.span`
  font-size: 80px;
  line-height: 1.2;
  background: hsla(334, 70%, 80%, 1);
  background-color: hsla(334, 70%, 80%, 1);
  background: conic-gradient(
    hsla(334, 70%, 80%, 1) 16.666%,
    hsla(347, 70%, 80%, 1) 16.666%,
    hsla(347, 70%, 80%, 1) 33.333%,
    hsla(129, 70%, 80%, 1) 33.333%,
    hsla(129, 70%, 80%, 1) 50%,
    hsla(85, 70%, 80%, 1) 50%,
    hsla(85, 70%, 80%, 1) 66.666%,
    hsla(183, 70%, 80%, 1) 66.666%,
    hsla(183, 70%, 80%, 1) 83.333%,
    hsla(308, 70%, 80%, 1) 83.333%
  );

  /* Set thee background size and repeat properties. */
  background-size: 57%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media only screen and (max-width: 900px) {
    font-size: 45px;
    line-height: 1.2;
  }

  @media only screen and (max-width: 768px) {
    font-size: 30px;
    line-height: 1.2;
  }
  @media only screen and (max-width: 500px) {
    font-size: 26px;
    line-height: 1.2;
  }
`;

function OverlayBg() {
  const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
  const FadeUp = batch(Fade(), Move(), Sticky());
  const ZoomInScroll = batch(Sticky(), Fade(), ZoomIn());
  return (
    <ScrollContainer>
      {/* <ScrollPage page={0}>
        <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -1000))}>
          <OverlayBgStyles
            style={{
              fontSize: "90px",
            }}
          >
            Welcome to my portfolio
          </OverlayBgStyles>
        </Animator>
      </ScrollPage> */}
      <ScrollPage page={1}>
        <Animator animation={ZoomInScroll}>
          <FunkyText>my name is ayesha siddiqua</FunkyText>
        </Animator>
      </ScrollPage>
      <ScrollPage page={2}>
        <Animator animation={FadeUp}>
          <FunkyText>I'm a front end web developer</FunkyText>
        </Animator>
      </ScrollPage>
      <ScrollPage page={3}>
        <div>
          <span>
            <Animator animation={MoveIn(-100, 0)}>
              <FunkyText>My skillset include</FunkyText>
            </Animator>
            <Animator animation={MoveIn(100, 0)}>
              <FunkyText>HTML5</FunkyText>
            </Animator>
            <FunkyText>
              CSS3
              <br />
              Bootstrap4
              <br />
              PHP
              <br />
              JavaScript
            </FunkyText>
            <Animator animation={MoveOut(100, 0)}>
              <FunkyText>JQuery</FunkyText>
            </Animator>
            <Animator animation={MoveOut(-100, 0)}>
              <FunkyText>ReactJs</FunkyText>
            </Animator>
          </span>
        </div>
      </ScrollPage>
      <ScrollPage page={4}>
        <Animator animation={batch(Fade(), Sticky())}>
          <FunkyText>
            Knowledge on designing softwares like Adobe Photoshop, Figma
          </FunkyText>
        </Animator>
      </ScrollPage>
      <ScrollPage page={5}>
        <Animator animation={ZoomInScrollOut}>
          <FunkyText>
            Extensive experience in responsive web design and cross browser
            compatibility
          </FunkyText>
        </Animator>
      </ScrollPage>
      <ScrollPage page={6}>
        <Animator animation={batch(Fade(), Sticky())}>
          <Experience></Experience>
        </Animator>
      </ScrollPage>
      
      <ScrollPage page={7}>
        <Animator animation={batch(Fade(), Sticky())}>
          <div className="container">
          <div className="row">
        <div className="col-sm-12">
          <h3>
            Currently employed with
            <br />
            <a
              href="https://www.votigo.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.votigo.com/assets/img/corp/logo.png?v=2"
                loading="lazy"
                width="300"
                alt=""
                
              />
            </a>
          </h3>         
        </div>
      </div>
      </div>
        </Animator>
      </ScrollPage>
      <div class="examples examples-flix">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h3>
              List of examples to demonstrate my work at Flixmedia:
              </h3>
            </div>
            <div className="col-sm-6">
                <a
                  href="http://media.flixcar.com/delivery/inpage/show/6/mx/2006505"
                  target="_blank"
                  rel="noreferrer"
                >
                  Honor
                </a>
                <a
                  href="http://media.flixcar.com/delivery/inpage/show/6/de/1560812"
                  target="_blank"
                  rel="noreferrer"
                >
                  Amazon
                </a>
                <a
                  href="http://media.flixcar.com/delivery/inpage/show/6/fr/2005026"
                  target="_blank"
                  rel="noreferrer"
                >
                  Huawei
                </a>
                <a
                  href="http://media.flixcar.com/delivery/inpage/show/6/fr/1993370"
                  target="_blank"
                  rel="noreferrer"
                >
                  Fujifilm
                </a>
                <a
                  href="http://media.flixcar.com/delivery/inpage/show/6/en/1997303"
                  target="_blank"
                  rel="noreferrer"
                >
                  Oppo
                </a>
                <a
                  href="http://media.flixcar.com/delivery/inpage/show/6/en/1968840"
                  target="_blank"
                  rel="noreferrer"
                >
                  Logitech           
                </a>
                <a
                  href="http://media.flixcar.com/delivery/inpage/show/6/en/1964132"
                  target="_blank"
                  rel="noreferrer"
                >
                  Intel          
                </a>
                <a
                  href="http://media.flixcar.com/delivery/inpage/show/6/tw/1829396"
                  target="_blank"
                  rel="noreferrer"
                >
                  Intel        
                </a>
            </div>
          </div>
        </div>
      </div>
      <div class="examples examples-votigo">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h3>
                List of examples to demonstrate my work at Votigo:
              </h3>
            </div>
            <div className="col-sm-6">
              <a href="https://www.magicatmcd.com/amoe" target="_blank" rel="noreferrer">McD</a>
              <a href="https://michelin.votigo.com/fbsweeps/sweeps/2022IMSAMICHELINGTCHALLENGEATVIR" target="_blank" rel="noreferrer">
                Michelin
              </a>
              <a href="https://sweeppromo.com/fbsweeps/sweeps/MatuaRockRefreshSweepstakes" target="_blank" rel="noreferrer">
                Treasury Wines
              </a>
              <a href="https://sweeppromo.com/fbsweeps/sweeps/StHubertsTheStagGearUpForSummerSweeps" target="_blank"rel="noreferrer">
              Treasury Wines
              </a>
              <a href="https://sqa10qasweeppromossmb.votigo.com/s/pages/InfamousTriviawith19CrimesOffer/agegate" target="_blank" rel="noreferrer">
                19 Crimes
              </a>
            </div>
          </div>
        </div>
      </div>
      <ContactInfo></ContactInfo>
        
    </ScrollContainer>
  );
}
export default OverlayBg;