import "./Bg.css";
function Experience() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <h3>
            2 years of proven experience with
            <br />
            <a
              href="https://www.flixmedia.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://assets.website-files.com/60c88869b8a3b1f8408650dc/60c88869b8a3b171bd865117_flixmedia-logo.svg"
                loading="lazy"
                width="180"
                alt=""
                className="image-2"
              />
            </a>
          </h3>
          <p>
            Had the opportunity to work with brands like HP, Dell, Sony, Amazon,
            Bose, Intel, Huawei, Sonos, Logitech, Microsoft, JBL, Polaroid,
            Withings, LG, Electrolux, Whirlpool, Vestel, Cusinart, Lego,
            SteelSeries, etc.
          </p>
          
        </div>

      </div>
      
    </div>
  );
}
export default Experience;
